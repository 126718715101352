import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'gatsby';
import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={config.siteTitle} />
        <SEO title="404: Not found" />
        <h1>Page non trouvée</h1>
        <p>Vous voici dans un lieu inconnu... the sadness.</p>
        <p>
          Pas de panique, en cliquant
          {' '}
          <Link to="/">ici</Link>
          , vous retrouverez votre chemin.
        </p>
      </Layout>
    );
  }
}

export default NotFoundPage;
